<template>
  <v-data-iterator
    :items="users"
    :items-per-page.sync="itemsPerPage"
    :page.sync="page"
    :search="search"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    no-results-text="No persons found"
    loading="false"
    loading-text="Looking for people..."
    hide-default-footer
  >
    <template v-slot:header>
      <v-toolbar rounded dark dense color="secondary" class="mb-3">
        <v-text-field
          dense
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search"
          @click="fetchAdditionalUserProperties()"
        ></v-text-field>
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-select
            class="ml-4"
            dense
            dark
            v-model="sortBy"
            flat
            solo-inverted
            hide-details
            :items="filterKeys"
            item-value="key"
            item-text="text"
            prepend-inner-icon="mdi-sort-alphabetical-ascending"
            label="Sort by"
          ></v-select>
          <v-spacer></v-spacer>

          <v-btn-toggle v-model="showSkills" class="mr-2" dense>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :loading="isloadingSkills"
                  :class="{ 'disable-events': isloadingSkills }"
                  color="vicyellow"
                >
                  <v-icon>mdi-head-lightbulb</v-icon>
                </v-btn>
              </template>
              <span>{{
                isloadingSkills
                  ? "Loading skills..."
                  : showSkills !== undefined
                  ? "Hide skills"
                  : "Show skills"
              }}</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-btn-toggle dense v-model="sortDesc" mandatory>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" depressed color="vicblue" :value="false">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>Sort ascending</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" depressed color="vicblue" :value="true">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>Sort descending</span>
            </v-tooltip>
          </v-btn-toggle>
        </template>
        <v-progress-linear
          :active="isLoading"
          height="4"
          color="vicyellow"
          indeterminate
          absolute
          bottom
        ></v-progress-linear>
      </v-toolbar>
    </template>

    <template v-if="!isLoading" v-slot:default="props">
      <v-row height="100%">
        <v-col
          class="pa-2"
          v-for="item in props.items"
          :key="item.id"
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="4"
        >
          <useravatar
            v-bind:userId="item.id"
            v-bind:showSkills="showSkills"
            v-on:chip-clicked="search = $event"
          ></useravatar>
        </v-col>
      </v-row>
    </template>

    <template v-if="!isLoading && users.length > 0" v-slot:footer>
      <v-row class="mx-3 my-1" align="center" justify="center">
        <span>Items per page</span>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text class="ml-2" v-bind="attrs" v-on="on">
              {{ itemsPerPage }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(number, index) in itemsPerPageArray"
              :key="index"
              @click="updateItemsPerPage(number)"
            >
              <v-list-item-title>{{ number }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>

        <span class="mr-4"> Page {{ page }} of {{ numberOfPages }} </span>
        <v-btn x-small fab dark color="vicblue" class="mr-1" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn x-small fab dark color="vicblue" class="mr-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </template>
  </v-data-iterator>
</template>
<script>
// import { mapActions } from "vuex";
import useravatar from "../components/userAvatar.vue";
import User from "../models/User";
import graph from "../plugins/graph";

export default {
  components: {
    useravatar,
  },
  data: () => ({
    filterKeys: [
      { key: "givenName", text: "First name" },
      { key: "surname", text: "Last name" },
      { key: "mail", text: "E-mail" },
      { key: "officeLocation", text: "Office" },
      { key: "jobTitle", text: "Job title" },
    ],
    itemsPerPage: 0,
    search: "",
    sortDesc: false,
    showSkills: 0,
    page: 1,
    sortBy: "givenName",
    windowSize: {
      x: 0,
      y: 0,
    },
    fetchedAllSkills: false,
    isLoading: true,
    isloadingSkills: true,
  }),

  computed: {
    users() {
      return User.all();
    },
    numberOfPages() {
      return Math.ceil(this.users.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.filterKeys.filter((key) => key !== "displayName");
    },
    numberOfCols() {
      return this.windowSize.x > 1920 ? 3 : 4;
    },
  },

  watch: {},

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  created() {},

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    // await this.fetchAllUsersDetails({ self: this });
    const userResponse = await graph.fetchAllUsersDetails();

    User.insert({
      data: userResponse.value,
    });
    this.isLoading = false;

    // await Promise.allSettled(
    const allResults = await Promise.allSettled(
      this.users.map((user) => graph.fetchUserDetailsById(user, ["id", "skills"]))
    );
    // console.log(allResults);
    for (const result of allResults) {
      if (result.value) {
        User.update({ where: result.value.id, data: { skills: result.value.skills } });
      }
    }
    // );
    this.isloadingSkills = false;
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          this.itemsPerPage = 10;
          this.itemsPerPageArray = [10, 20];
          break;
        case "sm":
          this.itemsPerPage = 10;
          this.itemsPerPageArray = [10, 20];
          break;
        case "md":
          this.itemsPerPage = 10;
          this.itemsPerPageArray = [10, 20];
          break;
        case "lg":
          this.itemsPerPage = 9;
          this.itemsPerPageArray = [9, 18];
          break;
        case "xl":
          this.itemsPerPageArray = [16, 32];
          this.itemsPerPage = 16;
          break;
      }
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async fetchAdditionalUserProperties() {
      if (!this.fetchedAllSkills) {
        this.fetchedAllSkills = true;

        // await Promise.allSettled(
        const allResults = await Promise.allSettled(
          this.users.map((user) => graph.fetchUserDetailsById(user, ["id", "skills"]))
        );
        // console.log(allResults);
        for (const result of allResults) {
          if (result.value) {
            User.update({ where: result.value.id, data: { skills: result.value.skills } });
          }
        }
      }
    },
    handleClick(val) {
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.shadow {
  box-shadow: none !important;
}
.disable-events {
  pointer-events: none;
}
</style>
