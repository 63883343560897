<template>
  <div height="100%" width="100%">
    <v-toolbar rounded dark dense color="secondary" class="mb-3">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-select
          class="ml-4"
          dense
          dark
          v-model="filterByYear"
          flat
          solo-inverted
          hide-details
          :items="activeYears"
          prepend-inner-icon="mdi-calendar-range"
          label="Select year"
        ></v-select>
        <v-spacer></v-spacer>

        <!-- <v-btn-toggle v-model="showSkills" class="mr-2" dense>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :loading="isloadingSkills"
                :class="{ 'disable-events': isloadingSkills }"
                color="vicyellow"
              >
                <v-icon>mdi-head-lightbulb</v-icon>
              </v-btn>
            </template>
            <span>{{
              isloadingSkills
                ? "Loading skills..."
                : showSkills !== undefined
                ? "Hide skills"
                : "Show skills"
            }}</span>
          </v-tooltip>
        </v-btn-toggle>
        <v-btn-toggle dense v-model="sortDesc" mandatory>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed color="vicblue" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </template>
            <span>Sort ascending</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed color="vicblue" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </template>
            <span>Sort descending</span>
          </v-tooltip>
        </v-btn-toggle> -->
      </template>
      <!-- <v-progress-linear
        :active="isLoading"
        height="4"
        color="vicyellow"
        indeterminate
        absolute
        bottom
      ></v-progress-linear> -->
    </v-toolbar>
    <v-row>
      <v-col>
        <v-card
          ><ag-charts-vue
            ref="total-projects"
            style="height: 30vh; width: 100%"
            :options="totalProjectsChartOptions"
          />
        </v-card>
      </v-col>

      <v-col>
        <v-card
          ><ag-charts-vue
            ref="total-hours-spent"
            style="height: 30vh; width: 100%"
            :options="totalHoursSpentChartOptions"
          /> </v-card
      ></v-col>
      <v-col>
        <v-card
          ><ag-charts-vue
            ref="average-productivity"
            style="height: 30vh; width: 100%"
            :options="productivityChartOptions"
          /> </v-card
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          ><ag-charts-vue
            ref="weekly-productivity"
            style="height: 50vh; width: 100%"
            :options="weeklyProductivityChartOptions"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AgChartsVue } from "ag-charts-vue";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-theme-material.css";
import graph from "../plugins/graph";
import TimeEntry from "../models/TimeEntry";
import CustomSorters from "../helpers/customSorters";
import moment from "moment";

export default {
  components: {
    AgChartsVue,
  },
  data: () => ({
    components: null,
    snack: { show: false, color: "", text: "", timeout: 3000 },
    filterByYear: null
  }),

  computed: {
    timeEntries() {
      return TimeEntry.query()
        .where("msdynDate", (value) => new Date(value).getFullYear() == this.filterByYear)
        .with(["msdynProject"])
        .orderBy("msdyn_date")
        .get();
    },
    activeYears() {
      return Array.from(
        new Set(
          TimeEntry.query()
            .all()
            .map((timeEntry) => {
              return new Date(timeEntry.msdynDate).getFullYear().toString();
            })
        )
      );
    },
    currentYear() {
      return new Date().getFullYear()
    },
    groupedProjects() {
      const aggregatedData = this.timeEntries.reduce(
        (acc, { vicProjectNumber, msdynDuration, msdynDate }) => {
          const year = new Date(msdynDate).getFullYear();
          const key = `${vicProjectNumber}-${year}`;
          if (!acc.has(key)) {
            acc.set(key, { vicProjectNumber, year, totalHours: 0 });
          }
          acc.get(key).totalHours += msdynDuration;
          return acc;
        },
        new Map()
      );

      return Array.from(aggregatedData.values()).map((project) => {
        return { ...project, totalHours: project.totalHours / 60 };
      });
    },
    totalProjects() {
      return this.groupedProjects.length;
    },
    averageProductivity() {
      return Math.round((this.totalProductiveHoursSpent / this.totalProjectHoursSpent) * 100);
    },
    totalProjectHoursSpent() {
      return (
        TimeEntry.query()
          .where("msdynDate", (value) => new Date(value).getFullYear() == this.filterByYear)
          .sum("msdynDuration") / 60
      );
    },
    totalProductiveHoursSpent() {
      return (
        TimeEntry.query()
          .where("msdynDate", (value) => new Date(value).getFullYear() == this.filterByYear)
          .with(["msdynProject"])
          .whereHas("msdynProject", (query) => {
            query.where("internal", false);
          })
          .sum("msdynDuration") / 60
      );
    },
    timeEntriesGroupedByWeek() {
      const windowSize = 4;
      const groupByWeek = this.timeEntries.reduce(
        (acc, { msdynDate, msdynDuration, msdynProject }, index, array) => {
          // Parse the date and find the start of the week (Monday)
          const startOfWeekDate = moment(msdynDate).startOf("isoWeek"); // isoWeek starts on Monday
          const weekKey = startOfWeekDate.format("YYYY-MM-DD");
          const weekNumber = startOfWeekDate.isoWeek();

          // Initialize the week group if it doesn't exist
          if (!acc[weekKey]) {
            acc[weekKey] = {
              week: weekKey,
              weekNumber,
              productiveHours: 0,
              nonProductiveHours: 0,
              productivePercentage: 0,
            };
          }

          // Aggregate the duration based on the internal flag
          if (msdynProject.internal) {
            acc[weekKey].nonProductiveHours += msdynDuration;
          } else {
            acc[weekKey].productiveHours += msdynDuration;
          }

          // Calculate the productive percentage
          const totalHours = acc[weekKey].productiveHours + acc[weekKey].nonProductiveHours;
          acc[weekKey].productivePercentage =
            totalHours > 0 ? (acc[weekKey].productiveHours / totalHours) * 100 : 0;

          return acc;
        },
        {}
      );

      const weeksArray = Object.values(groupByWeek)
        .sort((a, b) => a.weekNumber - b.weekNumber)
        .map((weekData, index, array) => {
          // Calculate the moving average for the productive percentage
          // Adjust the window size as needed
          if (index >= windowSize - 1) {
            let sum = 0;
            for (let i = 0; i < windowSize; i++) {
              sum += array[index - i].productivePercentage;
            }
            weekData.movingAverage = sum / windowSize;
          } else {
            weekData.movingAverage = null;
          }
          return weekData;
        });

      return weeksArray;
    },
    totalProjectsChartOptions() {
      return {
        data: [{ title: "All", amount: this.totalProjects }],
        container: this.$refs.totalProjects,
        title: {
          text: "Projects",
        },
        theme: {
          overrides: {
            donut: {
              series: {
                marker: {
                  size: 5,
                },
                tooltip: {
                  renderer: (params) => {
                    return {
                      title: "Projects",
                      content: `${params.datum.amount.toFixed(0)} projects`,
                    };
                  },
                },
              },
            },
          },
        },
        series: [
          {
            type: "donut",
            calloutLabelKey: "title",
            angleKey: "amount",
            innerRadiusRatio: 0.7,
            innerLabels: [
              {
                text: "Total ",
                fontWeight: "bold",
                fontSize: 12,
              },
              {
                text: this.totalProjects.toString(),
                margin: 8,
                fontSize: 24,
                // color: 'green',
              },
            ],
          },
        ],
      };
    },
    totalHoursSpentChartOptions() {
      return {
        data: this.groupedProjects,
        container: this.$refs.totalHoursSpent,
        title: {
          text: "Hours spent",
        },
        theme: {
          overrides: {
            donut: {
              series: {
                marker: {
                  size: 5,
                },
                tooltip: {
                  renderer: (params) => {
                    return {
                      title: params.vicProjectNumber,
                      content: `${params.datum.totalHours.toFixed(0)} hours`,
                    };
                  },
                },
              },
            },
          },
        },
        series: [
          {
            type: "donut",
            calloutLabelKey: "vicProjectNumber",
            angleKey: "totalHours",
            innerRadiusRatio: 0.7,
            innerLabels: [
              {
                text: "Total hours",
                fontWeight: "bold",
                fontSize: 10,
              },
              {
                text: this.totalProjectHoursSpent.toString(),
                margin: 8,
                fontSize: 16,
                // color: 'green',
              },
            ],
          },
        ],
      };
    },
    productivityChartOptions() {
      return {
        data: [
          { title: "Billable", amount: this.averageProductivity },
          { title: "Non-billable", amount: 100 - this.averageProductivity },
        ],
        container: this.$refs.averageProductivity,
        title: {
          text: "Billable hours",
        },
        theme: {
          overrides: {
            donut: {
              series: {
                marker: {
                  size: 5,
                },
                tooltip: {
                  renderer: (params) => {
                    return {
                      title: params.title,
                      content: `${params.datum.amount.toFixed(0)}%`,
                    };
                  },
                },
              },
            },
          },
        },
        series: [
          {
            type: "donut",
            calloutLabelKey: "title",
            angleKey: "amount",
            innerRadiusRatio: 0.7,
            innerLabels: [
              {
                text: "Average",
                fontWeight: "bold",
                fontSize: 12,
              },
              {
                text: `${this.averageProductivity.toFixed(0)}%`,
                margin: 8,
                fontSize: 24,
                // color: 'green',
              },
            ],
          },
        ],
      };
    },
    weeklyProductivityChartOptions() {
      return {
        data: this.timeEntriesGroupedByWeek,
        container: this.$refs.weeklyProductivity,
        title: {
          text: "Billable vs non-billable hours (weekly)",
        },
        theme: {
          overrides: {
            line: {
              series: {
                marker: {
                  size: 5,
                },
                tooltip: {
                  renderer: (params) => {
                    return {
                      title: params.yName,
                      content: `Week: ${params.datum.weekNumber} <br>Percentage:${
                        params.seriesId === "actual"
                          ? params.datum.productivePercentage.toFixed(1)
                          : params.datum.movingAverage.toFixed(1)
                      }%`,
                    };
                  },
                },
              },
            },
          },
        },
        series: [
          {
            id: "actual",
            type: "line",
            xKey: "weekNumber",
            xName: "week",
            yKey: "productivePercentage",
            yName: "Billability",
          },
          {
            id: "average",
            type: "line",
            xKey: "weekNumber",
            xName: "week",
            yKey: "movingAverage",
            yName: "Billability MA (4 weeks)",
          },
        ],
        axes: [
          {
            type: "number",
            title: { text: "Week" },
            position: "bottom",
            max: moment().week(),
          },
          {
            type: "number",
            position: "left",
            label: {
              format: "#{.0f} %",
            },
          },
        ],
      };
    },
    darkThemeIsActive() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    darkThemeIsActive(val) {
      this.gridApi.refreshCells({ force: true });
    },
  },

  created() {
    this.filterByYear = new Date().getFullYear().toString()
    this.getProjectData();
  },

  async mounted() {},

  destroyed() {},

  methods: {
    //Helpers,
    calculateMeanAverage(productiveHoursSum, totalHoursSum) {
      return (productiveHoursSum / totalHoursSum) * 100 || 0;
    },

    async getProjectData() {
      const msdynUser = await graph.getLoggedInMsdynUser();
      const response = await graph.fetchTimeEntriesByOwningUserId(msdynUser.UserId);

      if (response && response.value) {
        const timeEntries = response.value.map((timeEntry) => {
          return {
            id: timeEntry.msdyn_timeentryid,
            msdynDate: timeEntry.msdyn_date,
            msdynDuration: timeEntry.msdyn_duration,
            vicProjectNumber: timeEntry.vic_projectnumber,
            msdynEntryStatus:
              timeEntry["msdyn_entrystatus@OData.Community.Display.V1.FormattedValue"],
            bookableResource: {
              id: timeEntry.msdyn_bookableresource.bookableresourceid,
              name: timeEntry.msdyn_bookableresource.name,
              contractHours: timeEntry.msdyn_bookableresource.pyl_contracthours,
              msdynUser: {
                id: msdynUser.UserId,
              },
            },
            projectTask: {
              id: timeEntry.msdyn_projectTask.msdyn_projecttaskid,
              msdynSubject: timeEntry.msdyn_projectTask.msdyn_subject,
              vicHourCode: timeEntry.msdyn_projectTask?.vic_hourcodeid
                ? {
                    id: timeEntry.msdyn_projectTask.vic_hourcodeid.vic_hourcodeid,
                    code: timeEntry.msdyn_projectTask.vic_hourcodeid.vic_hourcode,
                  }
                : null,
              vicHourGroup: timeEntry.msdyn_projectTask?.vic_hourgroupid
                ? {
                    id: timeEntry.msdyn_projectTask.vic_hourgroupid.vic_hourgroupid,
                    name: timeEntry.msdyn_projectTask.vic_hourgroupid.vic_name,
                  }
                : null,
            },
            msdynProject: {
              id: timeEntry.msdyn_project.msdyn_projectid,
              msdynSubject: timeEntry.msdyn_project.msdyn_subject,
              vicProjectNumber: timeEntry.msdyn_project.vic_projectnumber,
              internal: timeEntry.msdyn_project.pyl_internal,
            },
          };
        });

        TimeEntry.insert({
          data: timeEntries,
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
