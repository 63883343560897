<template>
  <v-container class="py-0 my-0" fluid>
    <v-radio-group dense v-model="selectedValue" @change="updateFilter(selectedValue)">
      <v-radio class="text-subtitle-2" color="rgb(255, 64, 129)" v-for="option in values" :key="option" :label="option" :value="option"></v-radio>
    </v-radio-group>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: null,
      values: [],
    };
  },
  mounted() {
    this.selectedValue = this.params.defaultValue;
    this.values = this.params.values; // Get the values to display in the filter
  },
  methods: {
    updateFilter(value) {
      this.selectedValue = value;
      this.params.filterChangedCallback();
    },

    doesFilterPass(params) {
      return new Date(params.data.msdynDate).getFullYear().toString() === this.selectedValue;
    },

    isFilterActive() {
      return true;
    },

    // this example isn't using getModel() and setModel(),
    // so safe to just leave these empty. don't do this in your code!!!
    getModel() {},

    setModel() {},
  },
};
</script>
<style lang="scss">

</style>